.contact-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
}

.contact-container h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
}

.contact-methods {
    list-style: none;
    padding: 0;
    margin-bottom: 20px;
}

.contact-methods li {
    font-size: 1.2rem;
    margin-bottom: 10px;
}

.contact-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.form-group {
    display: flex;
    flex-direction: column;
}

.form-group label {
    font-size: 1.2rem;
    margin-bottom: 5px;
}

.form-group input,
.form-group textarea {
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
}

button {
    padding: 10px 20px;
    font-size: 1.2rem;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

button:hover {
    background-color: #0056b3;
}

.buttonContact{
    align-self: flex-start;

}

@media (max-width: 768px) {
    .contact-container h1 {
        font-size: 2rem;
    }

    .form-group label {
        font-size: 1rem;
    }

    button {
        font-size: 1rem;
    }
}