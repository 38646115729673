/* Structure principale du conteneur */
.about-container {
    display: flex;
    flex-direction: column; /* Utilise une colonne pour une meilleure lisibilité sur les petits écrans */
    align-items: center; /* Centre le contenu horizontalement */
    padding: 20px; /* Ajoute un peu de marge intérieure */
    gap: 20px; /* Espace entre le texte et le PDF */
    max-width: 1200px; /* Limite la largeur du conteneur principal */
    margin: 0 auto; /* Centre la section sur la page */
}

.text {
    max-width: 800px; /* Limite la largeur du texte */
    text-align: center; /* Centrage du texte pour un look plus moderne */
}

.text h1 {
    font-size: 2.5rem; /* Taille plus importante du titre */
    margin-bottom: 10px;
}

.text p {
    font-size: 1.2rem;
    line-height: 1.6; /* Améliore la lisibilité du texte */
}

.pdf-container {
    width: 100%;
    max-width: 800px; /* Limite de la largeur pour éviter un trop grand écran */
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1); /* Effet d'ombre légère pour le style */
    border-radius: 10px; /* Arrondi des coins */
    overflow: hidden; /* Empêche le dépassement des coins arrondis */
}

.pdf-viewer {
    width: 100%;
    height: 500px; /* Hauteur de l'iframe */
    border: none; /* Supprime les bordures */
}

@media (max-width: 768px) {
    .pdf-viewer {
        height: 400px; /* Ajuste la hauteur pour les petits écrans */
    }

    .text p {
        font-size: 1rem; /* Réduit la taille du texte pour les écrans plus petits */
    }
}

@media (max-width: 480px) {
    .pdf-viewer {
        height: 300px; /* Ajuste la hauteur pour les très petits écrans */
    }

    .text h1 {
        font-size: 2rem; /* Réduit la taille du titre pour les très petits écrans */
    }

    .text p {
        font-size: 0.9rem; /* Réduit encore la taille du texte */
    }
}
