/* navbar.css */

.navbar {
    background-color: #333;
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out;
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    width: 100vw;
}

.navbar.show {
    max-height: 500px; /* Adjust as needed */
    opacity: 1;
}

.navbar ul {
    list-style: none;
    display: flex;
    width: 100%;
    justify-content: space-around;
    margin: 0;
    padding: 0;
}

.navbar li {
    margin: 0 15px;
}

.navbar a {
    color: #fff;
    text-decoration: none;
    font-size: 1rem;
    transition: color 0.3s ease;
    text-align: center;
}

.navbar a:hover {
    color: #1E90FF;
}

.navbar select {
    background-color: #333;
    color: #fff;
    border: 1px solid #fff;
    padding: 5px;
    font-size: 1rem;
    cursor: pointer;
}

.mobile-menu-button {
    display: none;
    background-color: #5f5f5f;
    color: white;
    border: none;
    font-size: 24px;
    cursor: pointer;
    margin-left: 10px;
}

.overlay {
    display: flex;
    align-items: center;
    justify-content: start;
    background-color: #333;
    color: white;
    width: 100vw;
    z-index: 3;
    display: none;
} 

.overlay.show {
    display: flex;
}

.logo {
    font-size: 1.5rem;
    color: #fff;
    margin: 20px 20px;
}

/* Responsive styles */
@media (max-width: 768px) {
    .navbar {
        flex-direction: column;
        align-items: flex-start;
        display: none; /* Hide navbar by default on mobile */
        position: absolute;
        top: 60px;
        width: 100vw;
        padding: 0;
        background-color: #5f5f5f;
        z-index: 2;
        max-height: 0;
        opacity: 0;
        overflow: hidden;
    }

    .navbar.show {
        display: flex;
        border-radius: 0 0 10px 10px;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.227);
        max-height: 500px;
        opacity: 1;
        z-index: 3;
    }

    .navbar ul {
        flex-direction: column;
        margin: 20px 20px;
    }

    .navbar li {
        margin: 10px 0;
    }

    .navbar select {
        margin-top: 10px;
    }

    .mobile-menu-button {
        display: block;
    }
}

@media (min-width: 769px) {
    .navbar {
        display: flex; /* Always show navbar on larger screens */
        max-height: none;
        opacity: 1;
    }

    .mobile-menu-button {
        display: none;
    }
}